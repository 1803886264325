import { FC } from "react";
import DownloadCSVButton from "../svg/DownloadCSVButton";

interface CSVExportInputProps {
    title: string;
    link: string;
}

const CSVExportInput: FC<CSVExportInputProps> = ({ title, link }) => {
    return (
        <a href={link}>
            <DownloadCSVButton
                className="ml-2 w-[48px] h-[40px] fill-[#1D4ED8] cursor-pointer hover:bg-gray-200 hover rounded-xl"
                title={title}
                onClick={() => {}}
            />
        </a>
    );
};

export default CSVExportInput;
