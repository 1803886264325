import { MILLISECONDS_IN_ONE_SECOND } from "../domain/constants";
import { EmulationService } from "../domain/services/EmulationService";
import { IncidentService } from "../domain/services/IncidentService";
import { getRandomNumber } from "../tools/RandomTools";
import { incidentServiceFactory } from "./IncidentServiceImpl";

class EmulationServiceImpl implements EmulationService {
    private _timeout: NodeJS.Timeout = null;
    private _incidentService: IncidentService = incidentServiceFactory();
    private _onSuccessCallback = () => {};
    private _onErrorCallback = () => {};

    setEmulationCallbacks(
        successCallback: () => void,
        errorCallback: () => void
    ) {
        this._onSuccessCallback = successCallback;
        this._onErrorCallback = errorCallback;
    }

    startEmulation(
        useCERT: boolean,
        maxExtremum: number,
        minExtremum: number = 0
    ) {
        this._timeout = setTimeout(() => {
            this._incidentService
                .createIncident(
                    this._incidentService.createMockIncident(useCERT)
                )
                .then(this._onSuccessCallback)
                .catch(this._onErrorCallback);
            if (this._timeout) {
                this.startEmulation(useCERT, maxExtremum, minExtremum);
            }
        }, getRandomNumber(maxExtremum * MILLISECONDS_IN_ONE_SECOND, minExtremum * MILLISECONDS_IN_ONE_SECOND));
    }

    stopEmulation() {
        clearInterval(this._timeout);
        this._timeout = null;
    }
}

export function emulationServiceFactory(): EmulationService {
    return new EmulationServiceImpl();
}
