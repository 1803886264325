import { FC } from "react";
import CustomDropdown from "../shared/form-inputs/CustomDropdown";
import IncidentCreationModalForm from "./IncidentCreationModalForm";
import IncidentsCSVImportButton from "./IncidentsCSVImportButton";
import IncidentsDeletionButton from "./IncidentsDeletionButton";
import IncidentsEmulationMenu from "./IncidentsEmulationMenu";
import IncidentsQueueSortingMenu from "./IncidentsQueueSortingMenu";
import IncidentsTableTabulation from "./IncidentsTableTabulation";
import { Incident } from "../../domain/models/Incident";
import { IncidentDTO } from "../../domain/models/dto/IncidentDTO";
import { enumToSelectProps, enumValueToSelectProps } from "../../tools/EnumTools";
import { PaginationEnum } from "../../domain/enums/common/PaginationEnum";
import { SelectValue } from "../../types/SelectProps";
import { TabsEnum } from "../../domain/enums/TabsEnum";
import { useTranslation } from "react-i18next";
import IncidentsCSVExportButton from "./IncidentsCSVExportButton";

interface IncidentsTableManagementProps {
    errorCSVImportCallback: () => void;
    successCSVImportCallback: () => void;
    resetSelectedIncident: () => void;
    selectedIncident: Incident;
    createOrUpdateIncident: (values: IncidentDTO) => Promise<void>;
    setLoading: (value: boolean) => void;
    successEmulationCallback: () => void;
    errorEmulationCallback: () => void;
    updateQueueCallback: () => void;
    deleteAllIncidents: () => Promise<void>;
    limitHandler: (value: SelectValue) => void;
    tabName: TabsEnum;
    setTabName: (value: TabsEnum) => void;
    extendedEnable: boolean;
    setExtendedEnable: (value: boolean) => void;
    page: number;
    limit: number;
}

const IncidentsTableManagement: FC<IncidentsTableManagementProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="w-[95%] flex items-center justify-between ml-[2.5%]">
            <div className="relative bg-gray-100 w-auto h-[126px] mb-[10px] py-2 px-3 flex items-center justify-between shadow-md rounded-xl">
                <div className="flex h-full items-center">
                    <div className="border-gray-300 pr-4">
                        <IncidentCreationModalForm
                            resetSelectedIncident={props.resetSelectedIncident}
                            selectedIncident={props.selectedIncident}
                            createOrUpdateIncident={props.createOrUpdateIncident}
                        />
                        <IncidentsDeletionButton deleteHandler={props.deleteAllIncidents} />
                    </div>
                    <div className="relative flex h-full items-center justify-center border-r-[1px] border-l-[1px] border-gray-300">
                        <div className="flex h-full flex-col items-center px-4">
                            <h3 className="px-3 py-[2px] mb-[10px] text-[16px] font-semibold text-gray-700">
                                {t("incident.csv.actions")}
                            </h3>
                            <div className="flex mt-2">
                                <IncidentsCSVImportButton
                                    successCallback={props.successCSVImportCallback}
                                    errorCallback={props.errorCSVImportCallback}
                                    setLoading={props.setLoading}
                                />
                                <IncidentsCSVExportButton page={props.page} limit={props.limit} />
                            </div>
                        </div>
                    </div>
                    <IncidentsEmulationMenu
                        successEmulationCallback={props.successEmulationCallback}
                        errorEmulationCallback={props.errorEmulationCallback}
                    />
                    <div className="flex h-full flex-col items-center px-4">
                        <h3 className="px-3 py-[2px] text-[16px] font-semibold text-gray-700">
                            {t("incident.queueSorting.other")}
                        </h3>
                        <div className="flex flex-col items-start">
                            <IncidentsQueueSortingMenu
                                extendedEnable={props.extendedEnable}
                                setExtendedEnable={props.setExtendedEnable}
                                updateQueueCallback={props.updateQueueCallback}
                            />
                            <div className="flex items-center pr-4">
                                <h3 className="mr-2 text-[16px] font-semibold text-gray-500">{t("pagination.show")}</h3>
                                <CustomDropdown
                                    defaultValue={enumValueToSelectProps(PaginationEnum.SHOW_20, "pagination")}
                                    data={enumToSelectProps(PaginationEnum, "pagination")}
                                    dropDownCallback={props.limitHandler}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <IncidentsTableTabulation tabName={props.tabName} setTabName={props.setTabName} />
        </div>
    );
};

export default IncidentsTableManagement;
