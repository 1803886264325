import { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";

interface CustomToggleProps {
    labelKey: string;
    labelClassName?: string;
    name?: string;
    wrapperClassName?: string;
    value: boolean;
    errorKey?: string;
    disabled?: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const CustomToggle: FC<CustomToggleProps> = ({
    labelKey,
    labelClassName,
    name,
    wrapperClassName,
    value,
    errorKey,
    disabled,
    onChange,
}) => {
    const { t } = useTranslation();
    return (
        <div className={`flex items-center justify-center h-[40px] ${wrapperClassName}`}>
            {labelKey && (
                <label className={`mr-2 text-[16px] font-semibold text-gray-500 ${labelClassName}`} htmlFor={labelKey}>
                    {t(labelKey)}
                </label>
            )}
            <label className="mt-[4px] inline-flex items-center justify-center cursor-pointer">
                <input
                    name={name || ""}
                    type="checkbox"
                    value=""
                    className="fixed none sr-only peer"
                    checked={value}
                    disabled={disabled}
                    onChange={onChange}
                />
                <div className="relative w-11 h-6 bg-gray-200 rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            </label>
            <p className={`${errorKey ? "mt-1 ml-3" : ""} text-sm font-normal text-red-500`}>
                {errorKey ? t(errorKey) : name ? "\u00A0" : ""}
            </p>
        </div>
    );
};

export default CustomToggle;
