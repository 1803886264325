export function getRandomNumber(maxExtremum: number, minExtremum: number = 0) {
    return (
        Math.floor(Math.random() * (maxExtremum - minExtremum)) + minExtremum
    );
}

export function getRandomFromArray<T>(array: T[]): T {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
}

export function getRandomDate(start: Date, end: Date) {
    return new Date(
        start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );
}

export function getRandomWithProbabilities(obj: { [key: number]: number }) {
    const entries = Object.entries(obj).sort(
        ([_key1, value1], [_key2, value2]) => value2 - value1
    );
    const num = Math.random() * 100;

    let cumulativeSum = 0;
    for (const [key, value] of entries) {
        cumulativeSum += value;

        if (num < cumulativeSum) {
            return +key;
        }
    }
    return 0;
}
