import { FC } from "react";
import { TabsEnum } from "../../domain/enums/TabsEnum";
import { useTranslation } from "react-i18next";

interface IncidentsTableTabulationProps {
    tabName: TabsEnum;
    setTabName: (value: TabsEnum) => void;
}

const IncidentsTableTabulation: FC<IncidentsTableTabulationProps> = ({ tabName, setTabName }) => {
    const { t } = useTranslation();
    return (
        <div className="mb-[-70px] text-sm font-medium text-center text-gray-500 border-b border-gray-200">
            <ul className="flex flex-wrap">
                {Object.values(TabsEnum).map((tab, index) => (
                    <li className="me-2" key={`a-incidents-tabulation-${index}`}>
                        <a
                            href="#"
                            className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 ${
                                tabName === tab
                                    ? "bg-blue-100 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active"
                                    : ""
                            }`}
                            onClick={() => setTabName(tab)}
                        >
                            {t(`tabs.${tab}`)}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default IncidentsTableTabulation;
