import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseModal from "../shared/modals/BaseModal";
import DeleteTrashIconSVG from "../shared/svg/DeleteTrashIconSVG";

interface IncidentsDeletionButtonProps {
    deleteHandler: () => void;
}

const IncidentsDeletionButton: FC<IncidentsDeletionButtonProps> = ({ deleteHandler }) => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);

    const confirmHandler = () => {
        deleteHandler();
        setIsVisible(false);
    };

    return (
        <>
            <BaseModal
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                title={t("incident.incidentsDeletion.title")}
                buttonTitle={<DeleteTrashIconSVG iconColor="#fff" />}
                confirmHandler={confirmHandler}
                additionalButtonClassName="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-1.5 text-center w-[57px] h-8 mt-2"
                additionalConfirmButtonClassName="bg-red-600 hover:bg-red-700 focus:ring-4"
            >
                <div>{t("incident.incidentsDeletion.text")}</div>
            </BaseModal>
        </>
    );
};

export default IncidentsDeletionButton;
