import { FC } from "react";
import { useTranslation } from "react-i18next";
import { incidentServiceFactory } from "../../services/IncidentServiceImpl";
import CSVExportInput from "../shared/form-inputs/CSVExportInput";

interface IncidentsCSVExportButtonProps {
    page: number;
    limit: number;
}

const incidentService = incidentServiceFactory();

const IncidentsCSVExportButton: FC<IncidentsCSVExportButtonProps> = ({ page, limit }) => {
    const { t } = useTranslation();
    return (
        <CSVExportInput
            title={t("incident.csv.exportCSVFile")}
            link={incidentService.getExportIncidentsLink(page, limit)}
        />
    );
};

export default IncidentsCSVExportButton;
