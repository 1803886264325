import { apiURL } from "../Config";
import { DEFAULT_PAGE } from "../domain/constants";
import { PaginationEnum } from "../domain/enums/common/PaginationEnum";
import {
    ExtendedIncidentCollection,
    Incident,
    IncidentCollection,
} from "../domain/models/Incident";
import {
    ExtendedIncidentDTO,
    IncidentDTO,
} from "../domain/models/dto/IncidentDTO";
import { IncidentService } from "../domain/services/IncidentService";
import extendedIncidentMapperFactory from "../mappers/ExtendedIncidentMapper";
import incidentMapperFactory from "../mappers/IncidentMapper";
import { RemoveResponse } from "../types/ApiResults";
import { BaseServiceImpl } from "./BaseServiceImpl";
import { CERTIncidentDataEmulationService } from "./CERTIncidentDataEmulationService";
import { DefaultIncidentDataEmulationService } from "./DefaultIncidentDataEmulationService";

export class IncidentServiceImpl
    extends BaseServiceImpl
    implements IncidentService
{
    getExportIncidentsLink(
        page: number = DEFAULT_PAGE,
        limit: number = PaginationEnum.SHOW_10
    ) {
        return apiURL + `/api/v1/incidents/export?page=${page}&limit=${limit}`;
    }

    async getIncidents(
        page: number,
        limit: number
    ): Promise<IncidentCollection> {
        try {
            const mapper = incidentMapperFactory();
            const result = await this.getCollection<IncidentDTO>(
                `/api/v1/incidents/?page=${page}&limit=${limit}`,
                []
            );
            return {
                ...result,
                rows: result.rows.map((item) => mapper.fromDTO(item)),
            };
        } catch (err) {
            console.log("IncidentServiceImpl.getIncidents");
            console.log(err);
        }
    }

    async getQueue(page: number, limit: number): Promise<IncidentCollection> {
        try {
            const mapper = incidentMapperFactory();
            const result = await this.getCollection<IncidentDTO>(
                `/api/v1/queue/?page=${page}&limit=${limit}`,
                []
            );
            return {
                ...result,
                rows: result.rows.map((item) => mapper.fromDTO(item)),
            };
        } catch (err) {
            console.log("IncidentServiceImpl.getQueue");
            console.log(err);
        }
    }

    async getExtendedQueue(
        page: number,
        limit: number
    ): Promise<ExtendedIncidentCollection> {
        try {
            const mapper = extendedIncidentMapperFactory();
            const result = await this.getCollection<ExtendedIncidentDTO>(
                `/api/v1/queue/extended?page=${page}&limit=${limit}`,
                []
            );
            return {
                ...result,
                rows: result.rows.map((item) => mapper.fromDTO(item)),
            };
        } catch (err) {
            console.log("IncidentServiceImpl.getExtendedQueue");
            console.log(err);
        }
    }

    async createIncident(incident: Incident): Promise<Incident> {
        try {
            const mapper = incidentMapperFactory();
            return this.post("/api/v1/incidents", mapper.toDTO(incident));
        } catch (err) {
            console.log("IncidentServiceImpl.createIncident");
            console.log(err);
        }
    }

    async updateIncident(incident: Incident): Promise<Incident> {
        try {
            if (!incident?.id) {
                return null;
            }
            const mapper = incidentMapperFactory();
            return this.patch(
                `/api/v1/incidents/${incident.id}`,
                mapper.toDTO(incident)
            );
        } catch (err) {
            console.log("IncidentServiceImpl.updateIncident");
            console.log(err);
        }
    }

    async deleteIncident(incidentId: string): Promise<RemoveResponse> {
        try {
            if (!incidentId) {
                return { status: false };
            }
            return this.delete(`/api/v1/incidents/${incidentId}`);
        } catch (err) {
            console.log("IncidentServiceImpl.deleteIncident");
            console.log(err);
        }
    }

    createMockIncident(useCERT: boolean = false): Partial<Incident> {
        const mapper = incidentMapperFactory();

        const dto = useCERT
            ? CERTIncidentDataEmulationService.getData()
            : DefaultIncidentDataEmulationService.getData();

        return mapper.fromDTO(dto);
    }

    async deleteAllIncidents(): Promise<RemoveResponse> {
        try {
            return this.delete(`/api/v1/incidents/`);
        } catch (err) {
            console.log("IncidentServiceImpl.deleteAllIncidents");
            console.log(err);
        }
    }

    async createIncidentsFromCSV(file: File): Promise<Incident[]> {
        try {
            return this.sendFile(file, "/api/v1/incidents/csv");
        } catch (err) {
            console.log("IncidentServiceImpl.createIncidentsFromCSV");
            console.log(err);
        }
    }
}

export function incidentServiceFactory(): IncidentService {
    return new IncidentServiceImpl();
}
