import { FC, ReactNode, useState } from "react";

interface TooltipProps {
    children: ReactNode;
    childrenClassName?: string;
    wrapperClassName?: string;
    title?: string;
    titleClassName?: string;
    content: string | ReactNode;
    contentClassName?: string;
}

const Tooltip: FC<TooltipProps> = (props) => {
    const [isVisible, setIsVisible] = useState(false);
    return (
        <>
            <div
                className={props.childrenClassName}
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)}
            >
                {props.children}
            </div>
            {isVisible ? (
                <div className={`absolute flex items-center ${props.wrapperClassName}`}>
                    {props.title ? <div className={props.titleClassName}>{props.title}</div> : <></>}
                    <div className={props.contentClassName}>{props.content}</div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};
export default Tooltip;
