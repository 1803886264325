import { emulationDays } from '../Config';
import { CriticalityLevelEnum } from '../domain/enums/CriticalityLevelEnum';
import { IncidentFieldsEnum } from '../domain/enums/IncidentFieldsEnum';
import { ResultOfExposureEnum } from '../domain/enums/ResultOfExposureEnum';
import { SectorEnum } from '../domain/enums/SectorEnum';
import { VerificationStatusEnum } from '../domain/enums/VerificationStatusEnum';
import { IncidentDTO } from '../domain/models/dto/IncidentDTO';
import { IncidentEmulationParameters } from '../domain/models/IncidentEmulationParameters';
import { addDaysToNow } from '../tools/DatetimeTools';
import { getRandomNumberOfEnum } from '../tools/EnumTools';
import { getRandomDate, getRandomNumber, getRandomWithProbabilities } from '../tools/RandomTools';
import { v4 as uuidv4 } from 'uuid';
import { defaultIncidentEmulationParameters } from './DefaultIncidentDataEmulationService';

export const CERTIncidentEmulationParameters: IncidentEmulationParameters = {
    ...defaultIncidentEmulationParameters,
    [IncidentFieldsEnum.REPORT_RELIABLE]: [5, 95],
    [IncidentFieldsEnum.STATUS]: {
        [VerificationStatusEnum.IS_PRIMARY]: 75,
        [VerificationStatusEnum.IN_PROCESS]: 5,
        [VerificationStatusEnum.COMPLETED]: 10,
        [VerificationStatusEnum.PROCESSED_BY_CERT]: 4,
        [VerificationStatusEnum.PASSED_TO_ANOTHER_TEAM]: 3,
        [VerificationStatusEnum.RESOLVED_BY_ANOTHER_TEAM]: 3,
    },
    [IncidentFieldsEnum.CRITICALITY_LEVEL]: {
        [CriticalityLevelEnum.LOW]: 8.02,
        [CriticalityLevelEnum.MEDIUM]: 0.6,
        [CriticalityLevelEnum.HIGH]: 17.83,
        [CriticalityLevelEnum.CRITICAL]: 73.55,
    },
    [IncidentFieldsEnum.SECTOR]: {
        [SectorEnum.SECURITY_AND_DEFENSE_SECTOR]: 6.54,
        [SectorEnum.GOVERNMENT_AUTHORITIES]: 87.22,
        [SectorEnum.ENERGY_SECTOR]: 1.34,
        [SectorEnum.OTHER]: 4.9,
    },
    [IncidentFieldsEnum.RESULT_OF_EXPOSURE]: {
        [ResultOfExposureEnum.DATA_LEAK]: 0.3,
        [ResultOfExposureEnum.SYSTEM_BREACH]: 85.7,
        [ResultOfExposureEnum.OTHER]: 14,
    },
    LOSS: 5,
    DOWN: 60,
};

const LOSS_TO_CRY_DEPENDENCY: any = {
    [CriticalityLevelEnum.NOT_CRITICAL]: [1, 5],
    [CriticalityLevelEnum.LOW]: [3, 10],
    [CriticalityLevelEnum.MEDIUM]: [8, 40],
    [CriticalityLevelEnum.HIGH]: [37, 100],
    [CriticalityLevelEnum.CRITICAL]: [90, 300],
    [CriticalityLevelEnum.EXTRAORDINARY]: [280, 1000],
};

export class CERTIncidentDataEmulationService {
    static getData(): IncidentDTO {
        const emulatorParameters = CERTIncidentEmulationParameters;

        const criticalityLevel = getRandomWithProbabilities(emulatorParameters.CRY);

        const down = getRandomNumber(emulatorParameters.DOWN);
        const loss =
            getRandomNumber(LOSS_TO_CRY_DEPENDENCY[criticalityLevel][1], LOSS_TO_CRY_DEPENDENCY[criticalityLevel][0]) *
            CERTIncidentDataEmulationService.lossToDownCoefficient(down);

        return {
            id: uuidv4(),
            reportReliable: Boolean(getRandomWithProbabilities(emulatorParameters.RPT)),
            status: getRandomWithProbabilities(emulatorParameters.STATUS),
            criticalityLevel,
            cvss: getRandomNumber(emulatorParameters.CVSS) / 10,
            stateLayer: getRandomNumberOfEnum(emulatorParameters.STATE_LAYER),
            sector: getRandomWithProbabilities(emulatorParameters.SEC),
            loss,
            down,
            clientsAffected: getRandomNumberOfEnum(emulatorParameters.CLIENTS_AFFECTED),
            resultOfExposure: getRandomWithProbabilities(emulatorParameters.RE),
            dateTimeDiscovery: getRandomDate(new Date(), addDaysToNow(emulationDays)).toISOString(),
            dateTimeStart: getRandomDate(new Date(), addDaysToNow(emulationDays)).toISOString(),
            impactOnServices: getRandomNumberOfEnum(emulatorParameters.IMPACT_ON_SERVICE),
            netAmount: getRandomNumberOfEnum(emulatorParameters.NET_AMOUNT),
            affectedSystemType: getRandomNumberOfEnum(emulatorParameters.AFFECTED_SYSTEM_TYPE),
            dateTimeRegistration: getRandomDate(new Date(), addDaysToNow(emulationDays)).toISOString(),
            dateCreated: getRandomDate(new Date(), addDaysToNow(emulationDays)).toISOString(),
            dateModified: getRandomDate(new Date(), addDaysToNow(emulationDays)).toISOString(),
        };
    }

    static lossToDownCoefficient(down: number): number {
        switch (true) {
            case down > 0 && down <= 2:
                return 1;
            case down > 2 && down <= 8:
                return 1.2;
            case down > 8 && down <= 24:
                return 1.6;
            case down > 24 && down <= 48:
                return 2;
            case down > 48:
                return 2.5;
            default:
                return 1;
        }
    }
}
