import { FC, useState } from "react";
import BaseModal from "../shared/modals/BaseModal";
import { useTranslation } from "react-i18next";
import CustomDropdown from "../shared/form-inputs/CustomDropdown";
import { enumToSelectProps, enumValueToSelectProps } from "../../tools/EnumTools";
import { CriticalityLevelEnum } from "../../domain/enums/CriticalityLevelEnum";
import { IncidentFieldsEnum } from "../../domain/enums/IncidentFieldsEnum";
import { IncidentDTO } from "../../domain/models/dto/IncidentDTO";
import { incidentFields, incidentSchemaFactory } from "../../validation-schemas/IncidentSchema";
import useCustomFormik from "../../custom-hooks/useCustomFormik";
import CustomInput from "../shared/form-inputs/CustomInput";
import { InputType } from "../../types/InputType";
import { StateLayerEnum } from "../../domain/enums/StateLayerEnum";
import { SectorEnum } from "../../domain/enums/SectorEnum";
import { ClientsAffectedEnum } from "../../domain/enums/ClientsAffectedEnum";
import { ResultOfExposureEnum } from "../../domain/enums/ResultOfExposureEnum";
import { ImpactOnServicesEnum } from "../../domain/enums/ImpactOnServicesEnum";
import { NetAmountEnum } from "../../domain/enums/NetAmountEnum";
import { AffectedSystemTypeEnum } from "../../domain/enums/AffectedSystemTypeEnum";
import { VerificationStatusEnum } from "../../domain/enums/VerificationStatusEnum";
import CustomToggle from "../shared/form-inputs/CustomToggle";
import PlusIconSVG from "../shared/svg/PlusIconSVG";

interface IncidentCreationModalFormProps {
    selectedIncident: IncidentDTO;
    resetSelectedIncident: () => void;
    createOrUpdateIncident: (dto: IncidentDTO) => Promise<void>;
}

const IncidentCreationModalForm: FC<IncidentCreationModalFormProps> = ({
    selectedIncident,
    resetSelectedIncident,
    createOrUpdateIncident,
}) => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);

    const { form } = useCustomFormik<IncidentDTO>({
        fields: incidentFields,
        schema: incidentSchemaFactory(t),
        initial: selectedIncident,
        onSubmit: async (values, handlers) => {
            await createOrUpdateIncident(values);
            setIsVisible(false);
            handlers.resetForm();
        },
    });

    const closeHandler = () => {
        resetSelectedIncident();
        form.resetForm();
    };

    return (
        <BaseModal
            isVisible={!!selectedIncident?.id || isVisible}
            setIsVisible={setIsVisible}
            title={
                t(`incident.incidentCreation.${selectedIncident?.id ? "updateRecordTitle" : "newRecordTitle"}`) +
                (selectedIncident?.order ? ` ${selectedIncident.order}` : "")
            }
            buttonElement={
                <button
                    data-modal-target="static-modal"
                    data-modal-toggle="static-modal"
                    className={`flex items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-2.5 text-center h-[50px]`}
                    type="button"
                    title={
                        t(
                            `incident.incidentCreation.${selectedIncident?.id ? "updateRecordTitle" : "newRecordTitle"}`
                        ) + (selectedIncident?.order ? ` ${selectedIncident.order}` : "")
                    }
                    onClick={() => setIsVisible(true)}
                >
                    <PlusIconSVG className="w-[25px] fill-white" />
                </button>
            }
            confirmHandler={form.handleSubmit}
            closeHandler={closeHandler}
            additionalWrapClassName="w-[450px]"
        >
            <>
                <CustomInput
                    type={incidentFields.dateTimeRegistration.type as InputType}
                    labelKey={incidentFields.dateTimeRegistration.placeholderKey}
                    value={form.values.dateTimeRegistration}
                    name={"dateTimeRegistration"}
                    placeholderKey={incidentFields.dateTimeRegistration.placeholderKey}
                    errorKey={form.errors.dateTimeRegistration as string}
                    onChange={form.handleChange}
                />
                <CustomInput
                    type={incidentFields.dateTimeDiscovery.type as InputType}
                    labelKey={incidentFields.dateTimeDiscovery.placeholderKey}
                    value={form.values.dateTimeDiscovery}
                    name={"dateTimeDiscovery"}
                    placeholderKey={incidentFields.dateTimeDiscovery.placeholderKey}
                    errorKey={form.errors.dateTimeDiscovery as string}
                    onChange={form.handleChange}
                />
                <CustomInput
                    type={incidentFields.dateTimeStart.type as InputType}
                    labelKey={incidentFields.dateTimeStart.placeholderKey}
                    value={form.values.dateTimeStart}
                    name={"dateTimeStart"}
                    placeholderKey={incidentFields.dateTimeStart.placeholderKey}
                    errorKey={form.errors.dateTimeStart as string}
                    onChange={form.handleChange}
                />
                <CustomDropdown
                    labelKey={incidentFields.status.placeholderKey}
                    defaultValue={enumValueToSelectProps(
                        form.values.status,
                        `incident.${IncidentFieldsEnum.STATUS}.status`
                    )}
                    data={enumToSelectProps(VerificationStatusEnum, `incident.${IncidentFieldsEnum.STATUS}.status`)}
                    dropDownCallback={(value) => form.setFieldValue("status", value)}
                    wrapperClassName="block mb-8"
                    usePrefix
                />
                <CustomDropdown
                    labelKey={incidentFields.criticalityLevel.placeholderKey}
                    defaultValue={enumValueToSelectProps(
                        form.values.criticalityLevel,
                        `incident.${IncidentFieldsEnum.CRITICALITY_LEVEL}.level`
                    )}
                    data={enumToSelectProps(
                        CriticalityLevelEnum,
                        `incident.${IncidentFieldsEnum.CRITICALITY_LEVEL}.level`
                    )}
                    dropDownCallback={(value) => form.setFieldValue("criticalityLevel", value)}
                    wrapperClassName="block mb-8"
                    usePrefix
                />
                <CustomInput
                    type={incidentFields.cvss.type as InputType}
                    labelKey={incidentFields.cvss.placeholderKey}
                    value={form.values.cvss}
                    name={"cvss"}
                    placeholderKey={incidentFields.cvss.placeholderKey}
                    errorKey={form.errors.cvss as string}
                    onChange={form.handleChange}
                />
                <CustomDropdown
                    labelKey={incidentFields.stateLayer.placeholderKey}
                    defaultValue={enumValueToSelectProps(
                        form.values.stateLayer,
                        `incident.${IncidentFieldsEnum.STATE_LAYER}.subject`
                    )}
                    data={enumToSelectProps(StateLayerEnum, `incident.${IncidentFieldsEnum.STATE_LAYER}.subject`)}
                    dropDownCallback={(value) => form.setFieldValue("stateLayer", value)}
                    wrapperClassName="block mb-8"
                    usePrefix
                />
                <CustomDropdown
                    labelKey={incidentFields.sector.placeholderKey}
                    defaultValue={enumValueToSelectProps(
                        form.values.sector,
                        `incident.${IncidentFieldsEnum.SECTOR}.sector`
                    )}
                    data={enumToSelectProps(SectorEnum, `incident.${IncidentFieldsEnum.SECTOR}.sector`)}
                    dropDownCallback={(value) => form.setFieldValue("sector", value)}
                    wrapperClassName="block mb-8"
                    dropdownHeightClassName="max-h-[300px]"
                    usePrefix
                />
                <CustomInput
                    type={incidentFields.loss.type as InputType}
                    labelKey={incidentFields.loss.placeholderKey}
                    value={form.values.loss}
                    name={"loss"}
                    placeholderKey={incidentFields.loss.placeholderKey}
                    errorKey={form.errors.loss as string}
                    onChange={form.handleChange}
                />
                <CustomInput
                    type={incidentFields.down.type as InputType}
                    labelKey={incidentFields.down.placeholderKey}
                    value={form.values.down}
                    name={"down"}
                    placeholderKey={incidentFields.down.placeholderKey}
                    errorKey={form.errors.down as string}
                    onChange={form.handleChange}
                />
                <CustomDropdown
                    labelKey={incidentFields.clientsAffected.placeholderKey}
                    defaultValue={enumValueToSelectProps(
                        form.values.clientsAffected,
                        `incident.${IncidentFieldsEnum.CLIENTS_AFFECTED}.affected`
                    )}
                    data={enumToSelectProps(
                        ClientsAffectedEnum,
                        `incident.${IncidentFieldsEnum.CLIENTS_AFFECTED}.affected`
                    )}
                    dropDownCallback={(value) => form.setFieldValue("clientsAffected", value)}
                    wrapperClassName="block mb-8"
                    usePrefix
                />
                <CustomDropdown
                    labelKey={incidentFields.resultOfExposure.placeholderKey}
                    defaultValue={enumValueToSelectProps(
                        form.values.resultOfExposure,
                        `incident.${IncidentFieldsEnum.RESULT_OF_EXPOSURE}.result`
                    )}
                    data={enumToSelectProps(
                        ResultOfExposureEnum,
                        `incident.${IncidentFieldsEnum.RESULT_OF_EXPOSURE}.result`
                    )}
                    dropDownCallback={(value) => form.setFieldValue("resultOfExposure", value)}
                    wrapperClassName="block mb-8"
                    usePrefix
                />
                <CustomDropdown
                    labelKey={incidentFields.impactOnServices.placeholderKey}
                    defaultValue={enumValueToSelectProps(
                        form.values.impactOnServices,
                        `incident.${IncidentFieldsEnum.IMPACT_ON_SERVICES}.impact`
                    )}
                    data={enumToSelectProps(
                        ImpactOnServicesEnum,
                        `incident.${IncidentFieldsEnum.IMPACT_ON_SERVICES}.impact`
                    )}
                    dropDownCallback={(value) => form.setFieldValue("impactOnServices", value)}
                    wrapperClassName="block mb-8"
                    dropdownHeightClassName="max-h-[350px]"
                    usePrefix
                />
                <CustomDropdown
                    labelKey={incidentFields.netAmount.placeholderKey}
                    defaultValue={enumValueToSelectProps(
                        form.values.netAmount,
                        `incident.${IncidentFieldsEnum.NET_AMOUNT}.compromised`
                    )}
                    data={enumToSelectProps(NetAmountEnum, `incident.${IncidentFieldsEnum.NET_AMOUNT}.compromised`)}
                    dropDownCallback={(value) => form.setFieldValue("netAmount", value)}
                    wrapperClassName="block mb-8"
                    usePrefix
                />
                <CustomDropdown
                    labelKey={incidentFields.affectedSystemType.placeholderKey}
                    defaultValue={enumValueToSelectProps(
                        form.values.affectedSystemType,
                        `incident.${IncidentFieldsEnum.AFFECTED_SYSTEM_TYPE}.type`
                    )}
                    data={enumToSelectProps(
                        AffectedSystemTypeEnum,
                        `incident.${IncidentFieldsEnum.AFFECTED_SYSTEM_TYPE}.type`
                    )}
                    dropDownCallback={(value) => form.setFieldValue("affectedSystemType", value)}
                    wrapperClassName="block mb-8"
                    usePrefix
                />
                <CustomToggle
                    labelKey={incidentFields.reportReliable.placeholderKey}
                    name={"reportReliable"}
                    value={form.values.reportReliable}
                    errorKey={form.errors.reportReliable}
                    onChange={form.handleChange}
                />
            </>
        </BaseModal>
    );
};

export default IncidentCreationModalForm;
