import { emulationDays } from '../Config';
import { AffectedSystemTypeEnum } from '../domain/enums/AffectedSystemTypeEnum';
import { ClientsAffectedEnum } from '../domain/enums/ClientsAffectedEnum';
import { CriticalityLevelEnum } from '../domain/enums/CriticalityLevelEnum';
import { ImpactOnServicesEnum } from '../domain/enums/ImpactOnServicesEnum';
import { IncidentFieldsEnum } from '../domain/enums/IncidentFieldsEnum';
import { NetAmountEnum } from '../domain/enums/NetAmountEnum';
import { ResultOfExposureEnum } from '../domain/enums/ResultOfExposureEnum';
import { SectorEnum } from '../domain/enums/SectorEnum';
import { StateLayerEnum } from '../domain/enums/StateLayerEnum';
import { VerificationStatusEnum } from '../domain/enums/VerificationStatusEnum';
import { IncidentDTO } from '../domain/models/dto/IncidentDTO';
import { IncidentEmulationParameters } from '../domain/models/IncidentEmulationParameters';
import { addDaysToNow } from '../tools/DatetimeTools';
import { getRandomNumberOfEnum } from '../tools/EnumTools';
import { getRandomDate, getRandomNumber, getRandomWithProbabilities } from '../tools/RandomTools';
import { incidentFields } from '../validation-schemas/IncidentSchema';
import { v4 as uuidv4 } from 'uuid';

export const defaultIncidentEmulationParameters: IncidentEmulationParameters = {
    [IncidentFieldsEnum.REPORT_RELIABLE]: [20, 80],
    [IncidentFieldsEnum.STATUS]: {
        [VerificationStatusEnum.IS_PRIMARY]: 60,
        [VerificationStatusEnum.IN_PROCESS]: 10,
        [VerificationStatusEnum.COMPLETED]: 10,
        [VerificationStatusEnum.PROCESSED_BY_CERT]: 10,
        [VerificationStatusEnum.PASSED_TO_ANOTHER_TEAM]: 5,
        [VerificationStatusEnum.RESOLVED_BY_ANOTHER_TEAM]: 5,
    },
    [IncidentFieldsEnum.CRITICALITY_LEVEL]: {
        [CriticalityLevelEnum.NOT_CRITICAL]: 16.6,
        [CriticalityLevelEnum.LOW]: 16.6,
        [CriticalityLevelEnum.MEDIUM]: 16.7,
        [CriticalityLevelEnum.HIGH]: 16.7,
        [CriticalityLevelEnum.CRITICAL]: 16.7,
        [CriticalityLevelEnum.EXTRAORDINARY]: 16.7,
    },
    [IncidentFieldsEnum.SECTOR]: {
        [SectorEnum.NO_DATA]: 7.69,
        [SectorEnum.OTHER]: 7.69,
        [SectorEnum.MASS_MEDIA]: 7.69,
        [SectorEnum.ENTERPRISES_OF_THE_APPROPRIATE_OWNERSHIP_FORM]: 7.69,
        [SectorEnum.TRANSPORT_SECTOR]: 7.69,
        [SectorEnum.IT_SECTOR]: 7.69,
        [SectorEnum.LOCAL_GOVERNMENT_BODIES]: 7.69,
        [SectorEnum.ELECTRONIC_COMMUNICATIONS_SERVICES]: 7.69,
        [SectorEnum.OTHER_CRITICAL_ORGANIZATIONS]: 7.69,
        [SectorEnum.ENERGY_SECTOR]: 7.69,
        [SectorEnum.FINANCIAL_SECTOR]: 7.69,
        [SectorEnum.GOVERNMENT_AUTHORITIES]: 7.69,
        [SectorEnum.SECURITY_AND_DEFENSE_SECTOR]: 7.72,
    },
    [IncidentFieldsEnum.RESULT_OF_EXPOSURE]: {
        [ResultOfExposureEnum.NO_DATA]: 20,
        [ResultOfExposureEnum.OTHER]: 20,
        [ResultOfExposureEnum.LOSS_OF_FUNCTIONALITY_OF_SYSTEMS]: 20,
        [ResultOfExposureEnum.SYSTEM_BREACH]: 20,
        [ResultOfExposureEnum.DATA_LEAK]: 20,
    },
    CVSS: incidentFields.cvss.max,
    STATE_LAYER: StateLayerEnum,
    LOSS: incidentFields.loss.max,
    DOWN: incidentFields.down.max,
    CLIENTS_AFFECTED: ClientsAffectedEnum,
    IMPACT_ON_SERVICE: ImpactOnServicesEnum,
    NET_AMOUNT: NetAmountEnum,
    AFFECTED_SYSTEM_TYPE: AffectedSystemTypeEnum,
};

export class DefaultIncidentDataEmulationService {
    static getData(): IncidentDTO {
        const emulatorParameters = defaultIncidentEmulationParameters;
        return {
            id: uuidv4(),
            reportReliable: Boolean(getRandomWithProbabilities(emulatorParameters.RPT)),
            status: getRandomWithProbabilities(emulatorParameters.STATUS),
            criticalityLevel: getRandomWithProbabilities(emulatorParameters.CRY),
            cvss: getRandomNumber(emulatorParameters.CVSS) / 10,
            stateLayer: getRandomNumberOfEnum(emulatorParameters.STATE_LAYER),
            sector: getRandomWithProbabilities(emulatorParameters.SEC),
            loss: getRandomNumber(emulatorParameters.LOSS),
            down: getRandomNumber(emulatorParameters.DOWN),
            clientsAffected: getRandomNumberOfEnum(emulatorParameters.CLIENTS_AFFECTED),
            resultOfExposure: getRandomWithProbabilities(emulatorParameters.RE),
            dateTimeDiscovery: getRandomDate(new Date(), addDaysToNow(emulationDays)).toISOString(),
            dateTimeStart: getRandomDate(new Date(), addDaysToNow(emulationDays)).toISOString(),
            impactOnServices: getRandomNumberOfEnum(emulatorParameters.IMPACT_ON_SERVICE),
            netAmount: getRandomNumberOfEnum(emulatorParameters.NET_AMOUNT),
            affectedSystemType: getRandomNumberOfEnum(emulatorParameters.AFFECTED_SYSTEM_TYPE),
            dateTimeRegistration: getRandomDate(new Date(), addDaysToNow(emulationDays)).toISOString(),
            dateCreated: getRandomDate(new Date(), addDaysToNow(emulationDays)).toISOString(),
            dateModified: getRandomDate(new Date(), addDaysToNow(emulationDays)).toISOString(),
        };
    }
}
