import { ChangeEvent, FC, useRef } from "react";
import UploadCSVButton from "../svg/UploadCSVButton";

interface CSVImportInputProps {
    title: string;
    setFileCallback: (value: File) => void;
}

const CSVImportInput: FC<CSVImportInputProps> = (props) => {
    const fileInputRef = useRef(null);

    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) {
            return;
        }

        event.target.value = null;
        props.setFileCallback(selectedFile);
    };

    return (
        <>
            <input
                ref={fileInputRef}
                style={{ display: "none" }}
                type={"file"}
                accept={".csv"}
                onChange={handleFileChange}
            />
            <UploadCSVButton className="w-[48px] h-[40px] fill-[#1D4ED8] cursor-pointer hover:bg-gray-200 hover rounded-xl" title={props.title} onClick={handleUploadClick} />
        </>
    );
};

export default CSVImportInput;
