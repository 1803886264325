import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { emulationServiceFactory } from "../../services/EmulationServiceImpl";
import { maximumEmulationIntervalSec } from "../../Config";
import StartIconSVG from "../shared/svg/StartIconSvg";
import PauseIconSVG from "../shared/svg/PauseIconSVG";
import CustomToggle from "../shared/form-inputs/CustomToggle";
import QuestionMarkIconSVG from "../shared/svg/QuestionMarkIconSVG";
import Tooltip from "../shared/Tooltip";

const emulationService = emulationServiceFactory();

interface IncidentsEmulationMenuProps {
    successEmulationCallback: () => void;
    errorEmulationCallback: () => void;
}

const IncidentsEmulationMenu: FC<IncidentsEmulationMenuProps> = ({
    successEmulationCallback,
    errorEmulationCallback,
}) => {
    const [startedTime, setStartedTime] = useState<Date>(null);
    const [CERTMode, setCERTMode] = useState(true);
    const { t } = useTranslation();

    const startHandler = () => {
        setStartedTime(new Date());
        emulationService.startEmulation(CERTMode, maximumEmulationIntervalSec);
    };
    const stopHandler = () => {
        setStartedTime(null);
        emulationService.stopEmulation();
    };
    const isStarted = Boolean(startedTime);

    useEffect(() => {
        emulationService.setEmulationCallbacks(successEmulationCallback, errorEmulationCallback);
    }, []);

    return (
        <>
            <div className="flex flex-col h-full items-center pr-4 border-r-[1px] border-gray-300">
                <h3 className="px-3 py-[2px] text-[16px] font-semibold text-gray-700">
                    {t("incident.incidentsEmulation.emulationTitle")}
                </h3>
                <div className="relative flex flex-col justify-center items-center">
                    <div className="flex items-center justify-center ml-4 mb-1">
                        <CustomToggle
                            labelKey={"incident.incidentsEmulation.certMode"}
                            value={CERTMode}
                            onChange={() => setCERTMode(!CERTMode)}
                            disabled={!!startedTime}
                        />
                        <Tooltip
                            children={<QuestionMarkIconSVG className="ml-2 mt-[5px] w-[25px] fill-gray-400" />}
                            content={t("incident.incidentsEmulation.certModeDescription")}
                            wrapperClassName="right-10 w-[200px] rounded-xl space-x-1 shadow bg-white z-50 px-4 py-2 text-md text-gray-700"
                        />
                    </div>
                    <div className="flex items-center justify-center">
                        <button
                            className={`text-white h-[32px] bg-blue-700 hover:bg-blue-800 fill-white focus:outline-none font-medium rounded-lg text-sm px-5 py-1 text-center ${
                                isStarted ? "bg-gray-300 hover:bg-gray-300 focus:ring-0 cursor-default" : "focus:ring-4"
                            }`}
                            type="button"
                            title={t("incident.incidentsEmulation.emulationStart")}
                            onClick={startHandler}
                        >
                            <StartIconSVG className="w-[15px]" />
                        </button>
                        <button
                            className={`ml-2 text-white h-[32px] bg-blue-700 hover:bg-blue-800 fill-white focus:outline-none font-medium rounded-lg text-sm px-5 py-1 text-center ${
                                !isStarted
                                    ? "bg-gray-300 hover:bg-gray-300 focus:ring-0 cursor-default"
                                    : "focus:ring-4"
                            }`}
                            type="button"
                            title={t("incident.incidentsEmulation.emulationStop")}
                            onClick={stopHandler}
                        >
                            <PauseIconSVG className="w-[15px]" />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IncidentsEmulationMenu;
